import Form, { formCreate } from '../../../../../../components/form';
import { TextView } from '../../components';
// import request from '../../../../../../utils/request';

formCreate.component('textView', TextView);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_cost_account_invoice_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      // if (v.field === 'customerName') {
      //   v.value = this.formConfig.row.customerName;
      // } else if (v.field === 'accountCode') {
      //   v.value = this.formConfig.row.accountCode;
      // } else
      if (v.field === 'accountBudgetSubjectsCode') {
        v.restful = '/tpm/tpmAccountDetailController/financeSubjectsErpCodeByFindCode';
        v.optionsKey = { label: 'financeSubjectsErpName', value: 'financeSubjectsErpCode' };

        v.restfulParams = { fineCodeList: this.formConfig.row.fineCodeList };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const taxCode = this.getRule('taxCode');
      const invoiceAmount = this.getRule('invoiceAmount');
      const accountBudgetSubjectsCode = this.getRule('accountBudgetSubjectsCode');

      taxCode.on.change = (v) => {
        const { rate } = taxCode.options.find((sub) => sub.dictCode === v);
        if (invoiceAmount.value) {
          const num = (rate * invoiceAmount.value) / 100;
          this.setValue({
            taxAmount: num.toFixed(2),
          });
        }
      };

      invoiceAmount.on.change = (v) => {
        if (taxCode.value) {
          const { rate } = taxCode.options.find((sub) => sub.dictCode === taxCode.value);
          const num = (rate * v) / 100;
          this.setValue({
            taxAmount: num.toFixed(2),
          });
        }
      };

      accountBudgetSubjectsCode.on.change = (v) => {
        const { financeSubjectsErpName } = accountBudgetSubjectsCode.options.find((sub) => sub.financeSubjectsErpCode === v);
        this.setValue({
          accountBudgetSubjectsName: financeSubjectsErpName,
        });
      };

      this.setValue(this.formConfig.row);
      // if (this.formConfig.code === 'edit') {
      // }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const { code } = this.formConfig;

        formData.countAmount = Number(formData.invoiceAmount) + Number(formData.taxAmount);

        this.$emit('onClose');
        this.$emit('onGetList', {
          formData,
          code,
        });
      }
    },
  },
};
