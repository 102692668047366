var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { modalConfig: _vm.modalConfig, visible: _vm.modalConfig.visible },
      on: {
        "update:modalConfig": function ($event) {
          _vm.modalConfig = $event
        },
        "update:modal-config": function ($event) {
          _vm.modalConfig = $event
        },
        onClose: _vm.closeModal,
        "update:visible": function ($event) {
          return _vm.$set(_vm.modalConfig, "visible", $event)
        },
      },
    },
    [
      _c(_vm.formName, {
        ref: "modalForm",
        tag: "component",
        attrs: { formConfig: _vm.formConfig },
        on: { onGetList: _vm.getList, onClose: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }