import BasePage from '../../../../../components/table_page';

import Form from './form';

export default {
  name: 'activity_overview',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    // RegisterDetail,
    // Approve,
    // processForm,
  },
  created() {
    this.getConfigList('tpm_cost_account_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { approveStatus } = row;

      if (code === 'edit' && approveStatus === 'submit') {
        return false;
      }
      return true;
    },
    // 删除前置
    beforeButtonClick({ val }) {
      let list = [];
      let state = true;
      if (val.code === 'delete' && this.selectRow.length > 0) {
        list = this.selectRow.map((v) => v.approveStatus);

        state = !list.includes('submit');
        if (!state) {
          this.$message.error('状态为提交的数据不允许删除');
        }
      }

      return state;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
    },
  },
};
