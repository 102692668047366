<template>
  <Modal :modalConfig.sync="modalConfig" @onClose="closeModal" :visible.sync="modalConfig.visible">
    <component
      ref="modalForm"
      :is="formName"
      :formConfig="formConfig"
      @onGetList="getList"
      @onClose="closeModal"
    />
  </Modal>
</template>

<script>
import Modal from '../../../../../../components/modal';
import Form from './payment_type';
import invoiceForm from './invoice';

export default {
  name: 'modal',
  props: {
    configs: Object,
  },
  data() {
    return {
      // 弹框信息
      modalConfig: {
        type: 'Drawer',
        visible: false,
      },
      // 弹框数据
      formConfig: null,
      formName: 'Form',
    };
  },
  components: {
    Modal,
    Form,
    invoiceForm,
  },
  methods: {
    // 打开弹框
    openModal(params) {
      if (params) {
        const {
          title, formName, code, row,
        } = params;

        this.modalConfig.title = title || '标题';
        this.formName = formName || 'Form';
        this.formConfig = {
          code,
          row: row || {},
        };
      }

      this.modalConfig.visible = true;
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
    },
    getList(form) {
      this.$emit('resetList', form);
    },
  },
};
</script>

<style></style>
